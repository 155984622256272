import { createSlice } from "@reduxjs/toolkit";

export const isCustomJobRequestSlice = createSlice({
  name: "isCustomJobRequest",
  initialState: {
    isCustomJobRequest: "",
    customJobReqData: null
  },
  reducers: {
    handleCustomJob: (state, action) => {
      state.isCustomJobRequest = action.payload;
    },
    addCustomJobReqData: (state, action) => {
      state.customJobReqData = action.payload
    }
  },
});

export const { handleCustomJob, addCustomJobReqData } = isCustomJobRequestSlice.actions;

export default isCustomJobRequestSlice.reducer;
