import React, { isValidElement, useEffect, useState } from "react";
import "firebase/messaging";
import { toast } from "react-hot-toast";
import { useSelector } from "react-redux";
import FirebaseData from "../../Firebase/Firebase";
import { Fcmtoken } from "../../redux/UserData";
import { NotificationProps } from "../../typescriptTypes/globalTypes";

const PushNotificationLayout: React.FC<NotificationProps> = ({
  children,onNotificationReceived
}) => {
  // const [notification, setNotification] = useState(null);
  const [userToken, setUserToken] = useState(null);
  const [isTokenFound, setTokenFound] = useState(false);
  const [fcmToken, setFcmToken] = useState("");
  const { fetchToken, onMessageListener } = FirebaseData();

  const FcmToken = useSelector(Fcmtoken);

  const handleFetchToken = async () => {
    await fetchToken(setTokenFound, setFcmToken);
  };

  useEffect(() => {
    handleFetchToken();
  }, [userToken]);

  useEffect(() => {
    if (typeof window !== undefined) {
      setUserToken(FcmToken);
    }
  }, [userToken]);

  useEffect(() => {
    onMessageListener()
      .then((payload) => {
        if (payload && payload.data) {
          // setNotification(payload.data);
          if (onNotificationReceived) {
            onNotificationReceived(payload.data);
          }
        }
      })
      .catch((err) => {
        console.error('Error handling foreground notification:', err);
        toast.error('Error handling notification.');
      });
  }, [onNotificationReceived]);

  // / service worker
  useEffect(() => {
    if (fcmToken) {
      // Only register the service worker if fcmToken is not null
      if ("serviceWorker" in navigator) {
        window.addEventListener("load", function () {
          navigator.serviceWorker.register("./firebase-messaging-sw.js").then(
            function (registration) {
              console.log(
                "Service Worker registration successful with scope: ",
                registration.scope
              );
  
              // Pass environment variables to the service worker
              registration.active?.postMessage({
                type: "ENV_VARIABLES",
                env: {
                  API_KEY: process.env.REACT_APP_FIREBASE_API_KEY,
                  PROJECT_ID: process.env.REACT_APP_FIREBASE_PROJECT_ID,
                  STORAGE_BUCKET: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
                  MESSAGEING_SENDER_ID: process.env.REACT_APP_FIREBASE_MESSAGEING_SENDER_ID,
                  APP_ID: process.env.REACT_APP_FIREBASE_APP_ID,
                  MESUMENT_ID: process.env.REACT_APP_FIREBASE_MESUMENT_ID,
                },
              });
            },
            function (err) {
              console.log("Service Worker registration failed: ", err);
            }
          );
        });
      }
    }
  }, [fcmToken]);
  
  return <div>{isValidElement(children) ? React.cloneElement(children) : children}</div>
};

export default PushNotificationLayout;
