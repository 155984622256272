import { t } from "i18next"
import { IoStar } from "react-icons/io5"
import { formatAmount } from "../../../util/Helper"

const BiderCard = ({ data, currency_symbol, status, handleAddBidder }) => {
    return (
        <div className="bidder-card">
            <div className="provider-info">
                <div className="provider-image">
                    <img src={data?.provider_image} />
                </div>
                <div className="uper_div">

                    <div className="provider-details">
                        <div className="headline">
                            <h3>{data?.provider_name}</h3>
                            <div className="rating">
                                <span>{data?.rating}</span>
                                <IoStar size={18} />
                            </div>
                        </div>
                        <div className="service_info">

                            <div className="commanserviceDiv">
                                <span className='commanLbl'>
                                    {t("approxWorkDuration")}
                                </span>
                                <span className='commanValue'>
                                    {data?.duration} {t("minutes")}
                                </span>
                            </div>
                            <div className="commanserviceDiv">
                                <span className='commanLbl'>
                                    {t("bookingComplete")}
                                </span>
                                <span className='commanValue'>
                                    {data?.total_orders} {t("completed")}
                                </span>
                            </div>
                            <div className="commanserviceDiv">
                                <span className='commanLbl'>
                                    {t("providerNote")}
                                </span>
                                <span className='commanValue'>
                                    {data?.note}
                                </span>
                            </div>

                        </div>
                    </div>

                    <div style={{ width: "100%" }}>
                        <div className="bid-info">
                            <div className="commanserviceDiv">
                                <span className='commanLbl'>
                                    {t("bidPrice")}
                                </span>
                                <span className='commanValue'>
                                    {currency_symbol} {""} {formatAmount(data?.final_total)}
                                </span>
                            </div>
                            {status === "pending" &&
                                <button className="add-btn" onClick={()=>handleAddBidder(data)}>{t("bookNow")}</button>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BiderCard