import i18n from "i18next";
import Backend from "i18next-http-backend";
import { initReactI18next } from "react-i18next";
import { store } from "./redux/store"; // Import your Redux store
import config from "./config/config";

// Load language resources dynamically
const resources: Record<string, { translation: Record<string, string> }> = config.supportedLanguages.reduce(
  (acc, language) => {
    acc[language.langCode] = {
      translation: require(`./Languages/${language.langCode}.json`),
    };
    return acc;
  },
  {} as Record<string, { translation: Record<string, string> }>
);

// Initialize i18n
i18n
  .use(Backend) // For loading translations dynamically
  .use(initReactI18next) // Connect with React
  .init({
    debug: false,
    fallbackLng: config.defaultLanguage.langCode,
    resources,
    lng: config.defaultLanguage.langCode, // Default language from config
    interpolation: {
      escapeValue: false,
    },
  });

// Sync i18n language with Redux
store.subscribe(() => {
  const state = store.getState();
  const currentLanguage = state.language.currentLanguage; // Assuming your language reducer is named `language`
  if (i18n.language !== currentLanguage.langCode) {
    i18n.changeLanguage(currentLanguage.langCode);
    document.documentElement.dir = currentLanguage.isRtl ? "rtl" : "ltr"; // Update text direction if needed
  }
});

export default i18n;
