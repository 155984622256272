import Layout from "../../layout/Layout";
import {
  Box,
  Breadcrumbs,
  CircularProgress,
  Container,
  Grid,
  Typography,
  useTheme,
} from "@mui/material";
import { BreadcrumbLink } from "../../../CSS/ThemeStyle";
import { t } from "i18next";
import Pnavigation from "./Pnavigation";
import { LuPlusCircle } from "react-icons/lu";
import { Modal, Input, Select, DatePicker, Divider } from "antd";
import { useEffect, useState } from "react";
import { MdClose } from "react-icons/md";
import api from "../../../API/apiCollection";
import { useSelector } from "react-redux";
import MyServiceReqCard from "../Cards/MyServiceReqCard";
import { Link } from "react-router-dom";
import moment from "moment";
import dayjs from "dayjs";
import toast from "react-hot-toast";
import noService from "../../../Images/No-service.png";
import MyServiceReqCardSkeleton from "../Cards/MyServiceReqCardSkeleton";

const { TextArea } = Input;
const { Option } = Select;

const MyServices = () => {
  const theme = useTheme();

  const [isModalOpen, setIsModalOpen] = useState(false);

  const [isSubmit, setIsSubmit] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const [categories, setCategories] = useState([]);

  const [myReqs, setMyReqs] = useState([]);

  const limit = 5;

  const [offset, setOffset] = useState(0);

  const [hasMore, setHasMore] = useState(true);

  const location = useSelector((state) => state.Location);

  const settings = useSelector((state) => state.Settings)?.settings;
  const currency_symbol = settings?.app_settings?.currency;

  const locationData = useSelector((state) => state.Location);

  const fetchCategories = async () => {
    try {
      const categoryResponse = await api.get_category({
        latitude: location.lat,
        longitude: location.lng,
      });
      setCategories(categoryResponse?.data);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchMyRequests = async (reset = false) => {
    setIsLoading(true);
    try {
      const myReqResponse = await api.fetchMyCustomJobRequestsApi({
        limit: limit, // Pass the limit for pagination
        offset: offset, // Pass the current offset
      });

      const fetchedRequests = myReqResponse?.data?.data;
      if (reset) {
        // If resetting, replace the list
        setMyReqs(fetchedRequests);
      } else {
        // Append new requests to the existing list
        setMyReqs((prevRequests) => [...prevRequests, ...fetchedRequests]);
      }

      // If fewer than the limit is returned, no more data to load
      if (fetchedRequests.length < limit) {
        setHasMore(false);
      } else {
        setHasMore(true);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    if (isModalOpen) {
      fetchCategories();
    }
  }, [isModalOpen]);

  useEffect(() => {
    fetchMyRequests(true); // Fetch first set of data with reset
  }, []);
  useEffect(() => {}, [myReqs]);

  // Handle "Load More" click
  const handleLoadMore = () => {
    setOffset((prevOffset) => prevOffset + limit); // Increase offset
  };

  // Re-fetch data whenever offset changes
  useEffect(() => {
    if (offset > 0) {
      fetchMyRequests(); // Fetch the next set of data when offset changes
    }
  }, [offset]);

  // Single form data state object
  const [formData, setFormData] = useState({
    category: "",
    title: "",
    description: "",
    minPrice: "",
    maxPrice: "",
    requested_start_date: "",
    requested_start_time: "",
    requested_end_date: "",
    requested_end_time: "",
  });

  const [formErrors, setFormErrors] = useState({}); // State to keep track of form errors

  // Toggle modal visibility
  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setFormData({
      category: "",
      title: "",
      description: "",
      minPrice: "",
      maxPrice: "",
      requested_start_date: "",
      requested_start_time: "",
      requested_end_date: "",
      requested_end_time: "",
    });
  };

  const handleChange = (key, value) => {
    setFormData({
      ...formData,
      [key]: value,
    });
    setFormErrors((prevErrors) => ({ ...prevErrors, [key]: "" })); // Clear error for this field
  };
  const handlePriceChange = (key, value) => {
    // Only allow digits
    const digitsOnly = value.replace(/[^\d]/g, "");
    setFormData((prev) => ({ ...prev, [key]: digitsOnly }));
    setFormErrors((prevErrors) => ({ ...prevErrors, [key]: "" }));
  };
  // Handle date and time change
  const handleDateTimeChange = (key, value) => {
    if (value) {
      const formattedDate = dayjs(value).format("YYYY-MM-DD");
      const formattedTime = dayjs(value).format("HH:mm");
      setFormData({
        ...formData,
        [key === "startDuration"
          ? "requested_start_date"
          : "requested_end_date"]: formattedDate,
        [key === "startDuration"
          ? "requested_start_time"
          : "requested_end_time"]: formattedTime,
      });
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        [key === "startDuration"
          ? "requested_start_date"
          : "requested_end_date"]: "",
      }));
    }
  };

  // Function to reset form fields
  const resetForm = () => {
    setFormData({
      category: "",
      title: "",
      description: "",
      minPrice: "",
      maxPrice: "",
      requested_start_date: "",
      requested_start_time: "",
      requested_end_date: "",
      requested_end_time: "",
    });
    setFormErrors({});
  };

  const validateForm = () => {
    const errors = {};

    // Check if each field is filled and set custom error messages
    if (!formData.category) errors.category = t("Category is required.");
    if (!formData.title) errors.title = t("Title is required.");
    if (!formData.description)
      errors.description = t("Description is required.");
    if (!formData.minPrice) errors.minPrice = t("Minimum price is required.");
    if (!formData.maxPrice) errors.maxPrice = t("Maximum price is required.");
    if (!formData.requested_start_date)
      errors.requested_start_date = t("Start date is required.");
    if (!formData.requested_start_time)
      errors.requested_start_time = t("Start time is required.");
    if (!formData.requested_end_date)
      errors.requested_end_date = t("End date is required.");
    if (!formData.requested_end_time)
      errors.requested_end_time = t("End time is required.");

    // Check if minPrice is greater than maxPrice
    if (parseInt(formData.minPrice) > parseInt(formData.maxPrice)) {
      errors.maxPrice = t("Max price cannot be less than Min price.");
    }

    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  useEffect(() => {
    // Check if there are any form errors
    if (Object.keys(formErrors).length > 0) {
      // Reset form errors after 5 seconds
      const timeout = setTimeout(() => {
        setFormErrors({});
      }, 5000);

      // Cleanup timeout when component unmounts or errors change
      return () => clearTimeout(timeout);
    }
  }, [formErrors]);

  // Form submit handler
  const handleSubmit = async () => {
    if (!validateForm()) {
      return;
    }
    setIsSubmit(true);
    // Handle form submission logic here
    try {
      const response = await api.makeCustomJobRequestApi({
        category_id: formData?.category,
        service_title: formData?.title,
        service_short_description: formData?.description,
        min_price: formData?.minPrice,
        max_price: formData?.maxPrice,
        requested_start_date: formData?.requested_start_date,
        requested_start_time: formData?.requested_start_time,
        requested_end_date: formData?.requested_end_date,
        requested_end_time: formData?.requested_end_time,
        latitude: locationData?.lat ? locationData.lat : "",
        longitude: locationData?.lng ? locationData?.lng : "",
      });
      toast.success(response?.message);
      resetForm();
      setIsModalOpen(false);
      fetchMyRequests(true);
      setIsSubmit(false);
    } catch (error) {
      toast.error(error);
      console.log(error);
      setIsSubmit(false);
    }
  };

  return (
    <Layout>
      <Box paddingTop={"35px"} paddingBottom={"35px"} mt={2}>
        <Container maxWidth="lg" className="mainContainer">
          <Breadcrumbs
            separator="|"
            aria-label="breadcrumb"
            className="mb-1 mt-1"
          >
            <BreadcrumbLink to={"/"} className="breadcrumb" sx={{ mb: 0 }}>
              <strong> {t("home")}</strong>
            </BreadcrumbLink>
            <Typography color="text.primary">
              <strong>{t("profile")}</strong>
            </Typography>
          </Breadcrumbs>
          <Typography variant="h4" gutterBottom={true} sx={{ mt: "12px" }}>
            <strong>{t("myservice")}</strong>
          </Typography>
        </Container>
      </Box>

      <Container className="mainContainer" sx={{ mb: "30px", mt: "-50px" }}>
        <Grid spacing={3} container>
          <Grid item xs={12} md={4}>
            <Pnavigation />
          </Grid>
          <Grid item xs={12} md={8}>
            <Box
              sx={{ background: theme.palette.background.box }}
              mt={3}
              minHeight={570}
              borderRadius={4}
            >
              <div>
                <div className="headlineDiv">
                  <Typography variant="h5" fontWeight={"bold"} mb={2} pt={2}>
                    {t("myservice")}
                  </Typography>
                  {myReqs?.length > 0 && (
                    <button className="addmyservice" onClick={showModal}>
                      <span>
                        <LuPlusCircle size={22} />
                      </span>
                      <span>{t("addServiceReq")}</span>
                    </button>
                  )}
                </div>
                <Divider />
              </div>
              <Box padding={2}>
                <div className="myServicesCards">
                  {isLoading ? (
                    <div className="row" style={{ rowGap: "20px" }}>
                      {/* Skeleton Loading for 5 Cards */}
                      {[...Array(5)].map((_, index) => (
                        <div className="col-12" key={index}>
                          <MyServiceReqCardSkeleton />{" "}
                          {/* Replace this with your actual Skeleton component */}
                        </div>
                      ))}
                    </div>
                  ) : myReqs && myReqs.length > 0 ? (
                    <div className="row" style={{ rowGap: "20px" }}>
                      {myReqs.map((ele, index) => (
                        <div className="col-12" key={index}>
                          {/* <Link to={`/profile/my-service-request-details/${ele?.id}`}> */}
                          <MyServiceReqCard
                            data={ele}
                            currency_symbol={currency_symbol}
                          />
                          {/* </Link> */}
                        </div>
                      ))}

                      <div className="col-12">
                        {hasMore && (
                          <div className="loadMoreService">
                            <button onClick={handleLoadMore}>
                              {t("loadMore")}
                            </button>
                          </div>
                        )}
                      </div>
                    </div>
                  ) : (
                    <div className="serviceNotFound">
                      <div className="notfoundImg">
                        <img src={noService} alt="no_services" />
                      </div>
                      <div className="desc">
                        <p>{t("noReqServiceAvailable")}</p>
                        <button onClick={showModal} className="addmyservice">
                          <span>
                            <LuPlusCircle size={22} />
                          </span>
                          <span>{t("addServiceReq")}</span>
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Container>

      {/* Ant Design Modal */}
      <Modal
        open={isModalOpen}
        onCancel={handleCancel}
        closable={false}
        footer={null}
        width={700}
        className="reqServiceModal"
        style={{ top: "10%", borderRadius: "8px" }}
      >
        <div className="reqServiceHeader">
          <span>{t("addServiceReq")}</span>
          <button onClick={handleCancel}>
            <MdClose size={22} />
          </button>
        </div>
        <div className="reqServiceBody">
          <label className="formlbls">{t("category")}</label>
          <Select
            required
            value={formData.category}
            placeholder="Choose Category"
            style={{
              width: "100%",
              marginBottom: "10px",
              borderRadius: "4px",
              color: "darkgray",
            }}
            onChange={(value) => handleChange("category", value)}
          >
            <Option value="">Select Category</Option>
            {categories?.map((cate, index) => (
              <Option value={cate?.id} key={index}>
                {" "}
                {cate?.name}
              </Option>
            ))}
          </Select>
          {formErrors.category && (
            <p className="error_msg">{formErrors.category}</p>
          )}

          <label className="formlbls">{t("serviceTitle")}</label>
          <Input
            required
            value={formData.title}
            placeholder="Ex. Pest Control"
            style={{
              width: "100%",
              marginBottom: "10px",
              borderRadius: "4px",
              padding: "10px",
            }}
            onChange={(e) => handleChange("title", e.target.value)}
          />
          {formErrors.title && <p className="error_msg">{formErrors.title}</p>}

          <label className="formlbls">{t("sortDesc")}</label>
          <TextArea
            required
            value={formData.description}
            placeholder="Write Description"
            rows={3}
            style={{
              width: "100%",
              marginBottom: "10px",
              borderRadius: "4px",
              padding: "10px",
            }}
            onChange={(e) => handleChange("description", e.target.value)}
          />
          {formErrors.description && (
            <p className="error_msg">{formErrors.description}</p>
          )}

          <label className="formlbls">{t("price")}</label>
          <div
            style={{
              display: "flex",
              width: "100%",
              gap: "10px",
              marginBottom: "10px",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "5px",
                width: "100%",
              }}
            >
              <Input
                required
                value={formData.minPrice}
                placeholder="Min. Price"
                style={{ width: "100%", borderRadius: "4px", padding: "10px" }}
                onChange={(e) => handlePriceChange("minPrice", e.target.value)}
              />
              {formErrors.minPrice && (
                <p className="error_msg">{formErrors.minPrice}</p>
              )}
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "5px",
                width: "100%",
              }}
            >
              <Input
                required
                value={formData.maxPrice}
                placeholder="Max. Price"
                style={{ width: "100%", borderRadius: "4px", padding: "10px" }}
                onChange={(e) => handlePriceChange("maxPrice", e.target.value)}
              />
              {formErrors.maxPrice && (
                <p className="error_msg">{formErrors.maxPrice}</p>
              )}
            </div>
          </div>

          {/* Date Pickers with Errors */}
          <div
            style={{
              display: "flex",
              width: "100%",
              gap: "10px",
              marginBottom: "10px",
            }}
          >
            {/* Start Date Picker */}
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "5px",
                width: "100%",
              }}
            >
              <label className="formlbls">{t("reqStartTime")}</label>
              <DatePicker
                value={
                  formData.requested_start_date
                    ? dayjs(
                        formData.requested_start_date +
                          " " +
                          formData.requested_start_time
                      )
                    : null
                }
                showTime={{ format: "HH:mm" }}
                format="YYYY-MM-DD HH:mm"
                placeholder="Choose Date and Time"
                style={{
                  marginBottom: "10px",
                  borderRadius: "4px",
                  width: "100%",
                }}
                onChange={(value) =>
                  handleDateTimeChange("startDuration", value)
                }
                disabledDate={(current) =>
                  current && current < dayjs().startOf("day")
                } // Disable past dates
              />
              {formErrors.requested_start_date && (
                <p className="error_msg">{formErrors.requested_start_date}</p>
              )}
            </div>

            {/* End Date Picker */}
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "5px",
                width: "100%",
              }}
            >
              <label className="formlbls">{t("reqEndTime")}</label>
              <DatePicker
                value={
                  formData.requested_end_date
                    ? dayjs(
                        formData.requested_end_date +
                          " " +
                          formData.requested_end_time
                      )
                    : null
                }
                showTime={{ format: "HH:mm" }}
                format="YYYY-MM-DD HH:mm"
                placeholder="Choose Date and Time"
                style={{
                  marginBottom: "10px",
                  borderRadius: "4px",
                  width: "100%",
                }}
                onChange={(value) => handleDateTimeChange("endDuration", value)}
                disabledDate={(current) => {
                  const startDate = formData.requested_start_date
                    ? dayjs(formData.requested_start_date).startOf("day")
                    : null;
                  return (
                    current && current < (startDate || dayjs().startOf("day"))
                  ); // Disable dates before startDate or today
                }}
                disabledTime={() => {
                  const startDate = formData.requested_start_date
                    ? dayjs(formData.requested_start_date)
                    : null;
                  const startTime = formData.requested_start_date && formData.requested_start_time
                    ? dayjs(
                        formData.requested_start_date +
                          " " +
                          formData.requested_start_time
                      )
                    : null;
              
                  // Only disable times if the start and end date are the same
                  if (
                    startDate &&
                    formData.requested_end_date &&
                    startDate.isSame(formData.requested_end_date, "date") &&
                    startTime
                  ) {
                    const startHour = startTime.hour();
                    const startMinute = startTime.minute();
              
                    return {
                      disabledHours: () =>
                        [...Array(24).keys()].filter((hour) => hour < startHour),
                      disabledMinutes: (selectedHour) =>
                        selectedHour === startHour
                          ? [...Array(60).keys()].filter((minute) => minute < startMinute)
                          : [],
                    };
                  }
              
                  // No restrictions if dates are different
                  return {};
                }}
              />
              {formErrors.requested_end_date && (
                <p className="error_msg">{formErrors.requested_end_date}</p>
              )}
            </div>
          </div>

          <div className="reqServicesSubmit">
            <button onClick={handleSubmit}>
              {isSubmit ? (
                <CircularProgress size={24} color="inherit" /> // Show the loader when submitting
              ) : (
                t("submitReq") // Show text when not submitting
              )}
            </button>
          </div>
        </div>
      </Modal>
    </Layout>
  );
};

export default MyServices;
