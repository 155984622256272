import React from 'react';
import { Skeleton, Box, Typography } from '@mui/material';
import { IoStar } from 'react-icons/io5';

const BiderCardSkeleton = () => {
    return (
        <Box className="bidder-card" sx={{ padding: 2, border: '1px solid #ddd', borderRadius: 1, display: 'flex' }}>
            <Box className="provider-info" sx={{ display: 'flex', width: '100%' }}>
                <Box className="provider-image" sx={{ flexShrink: 0, mr: 2 }}>
                    <Skeleton variant="rectangular" width={80} height={80} />
                </Box>
                <Box className="uper_div" sx={{ flexGrow: 1 }}>
                    <Box className="provider-details" sx={{ mb: 1 }}>
                        <Box className="headline" sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <Skeleton variant="text" width={50} height={20} />
                            <Skeleton variant="text" width={40} height={20} />
                        </Box>
                        <Box className="rating" sx={{ display: 'flex', alignItems: 'center', mt: 0.5 }}>
                            <Skeleton variant="text" width={80} height={20} sx={{ mr: 0.5 }} />
                        </Box>
                    </Box>

                    <Box className="service_info" sx={{ mb: 1, width: "100%" }}>
                        <Box className="commanserviceDiv" sx={{ mb: 1, width: "100%" }}>
                            <Skeleton variant="text" width="40%" height={20} />
                            <Skeleton variant="text" width="60%" height={20} sx={{ mt: 1 }} />
                        </Box>
                        <Box className="commanserviceDiv" sx={{ mb: 1, width: "100%" }}>
                            <Skeleton variant="text" width="40%" height={20} />
                            <Skeleton variant="text" width="60%" height={20} sx={{ mt: 1 }} />
                        </Box>
                        <Box className="commanserviceDiv" sx={{ mb: 1, width: "100%" }}>
                            <Skeleton variant="text" width="40%" height={20} />
                            <Skeleton variant="text" width="60%" height={20} sx={{ mt: 1 }} />
                        </Box>
                    </Box>

                    <Box sx={{ width: "100%" }}>
                        <Box className="bid-info" sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <Box className="commanserviceDiv" sx={{ mb: 1,width: "100%" }}>
                                <Skeleton variant="text" width="40%" height={20} />
                                <Skeleton variant="text" width="60%" height={20} sx={{ mt: 1 }} />
                            </Box>
                            <Skeleton variant="rectangular" width={80} height={36} />
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

export default BiderCardSkeleton;
