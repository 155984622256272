import { t } from "i18next";
import { placeholderImage } from "../../../util/Helper";
import { Link } from "react-router-dom";


const MyServiceReqCard = ({ data, currency_symbol }) => {
    const maxVisibleBidders = 5;
    const extraBidders = data?.bidders?.length - maxVisibleBidders;
    return (
        <div className='card myReqCard'>
            <div className="service_div">
                <div className="details">
                    <div className='info'>
                        <span className="category">{data?.category_name}</span>
                        <span className="name">{data?.service_title}</span>
                        <span className="price">{currency_symbol} {""} {data?.min_price} - {currency_symbol} {""} {data?.max_price}</span>
                    </div>
                </div>
                {data?.status === "pending" ? (
                    <div className="pending_status">
                        <span>{t("requested")}</span>
                    </div>
                ) : data?.status === "cancelled" ? (
                    <div className="cancelled_status">
                        <span>{t("cancelled")}</span>
                    </div>
                ) : data?.status === "booked" ? (
                    <div className="approved_status">
                        <span>{t("booked")}</span>
                    </div>
                ) : null}

            </div>
            <div className="bids_div">
                {data?.bidders?.length > 0 ? (
                    
                    <div className="bidders">
                        <span>Bids: </span>
                        {data?.bidders.slice(0, maxVisibleBidders).map((bidder, index) => (

                            <img
                                key={index}
                                className="bidder-image"
                                src={bidder?.provider_image}
                                alt={`Bidder ${index + 1}`}
                                onError={placeholderImage}
                            />
                        ))}
                        {extraBidders > 0 && (
                            <span className="extra-bidders">
                                +{extraBidders}
                            </span>
                        )}
                    </div>
                ) : (
                    <span>{t("noBidsAvailable")}</span>
                )
                }
                <Link to={`/profile/my-service-request-details/${data?.id}`}>
                    <button className="view-details-btn">{t("viewDetails")}</button>
                </Link>
            </div>
        </div>
    )
}

export default MyServiceReqCard