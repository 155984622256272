import {
  Box,
  Button,
} from "@mui/material";
import React, { useState } from "react";
import { t } from "i18next";
import api from "../API/apiCollection";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";

const Paypal = ({ amount, isCustomJobRequest, customJobReqData }) => {
  const settings = useSelector((state) => state?.Settings)?.settings
    ?.payment_gateways_settings;
  const orderDetails = useSelector(
    (state) => state.OrderCartDetails
  )?.orderDetails;
  const delivery_type = useSelector(
    (state) => state.DeliveryAddress
  )?.deliveryType;

  const deliveryAddress = useSelector(
    (state) => state.DeliveryAddress
  )?.delivery;

  const date = orderDetails && orderDetails.date;
  const time = orderDetails && orderDetails.slot;

  const address_id = deliveryAddress;
  const address = address_id !== "" ? deliveryAddress : "";

  const [order_id, setOrderID] = useState(0);

  const [open, setOpen] = useState(false);

  const navigate = useNavigate();


  let promocode = useSelector((state) => state.Promocode);
  let selectedPromoCode = promocode.selectedPromoCode;

  const dispatch = useDispatch();
  const orderNotes = orderDetails.orderNote;

  const handlePayout = async () => {
    try {
      const result = await api.placeOrder({
        method: "paypal",
        date: date,
        time: time,
        addressId: delivery_type === "Home" ? address?.id : "",
        order_note: orderNotes,
        promo_code_id: selectedPromoCode ? selectedPromoCode.id : "",
        custom_job_request_id: isCustomJobRequest ? customJobReqData?.custom_job_request_id : "",
        bidder_id: isCustomJobRequest ? customJobReqData?.partner_id : ""
      });

      // setOpen(true);

      if (result.error === false || result.error === "false") {
        setOrderID(result.data.order_id);

        const paypal_link = result?.data?.paypal_link
        if (paypal_link) {
          // Open PayPal in new tab
          window.location.href = paypal_link;

        }
      } else {
        if (typeof result.message === "object") {
          Object.values(result.message).forEach((e) => {
            toast.error(e);
          });
        } else {
          toast.error(result.message);
        }
      }
    } catch (error) {
      console.error("Error placing order:", error);
      toast.error("Failed to place order. Please try again later.");
    }
  };

  return (
    <Box>
      <Button
        fullWidth
        sx={{ my: 1 }}
        variant="outlined"
        onClick={(e) => {
          handlePayout();
        }}
      >
        {t("make_a_payment")} {t("with")} {t("paypal")}
      </Button>
    </Box>
  );
};

export default Paypal;
