import { createSlice } from "@reduxjs/toolkit";

export const BookingFilterSlice = createSlice({
    name: "BookingFilter",
    initialState: {
        status: "all", // Directly define status here for easy access
    },
    reducers: {
        updateFilterStatus: (state, action) => {
            state.status = action.payload; // Update status directly
        },
    },
});

export const { updateFilterStatus } = BookingFilterSlice.actions;

export const selectBookingFilterStatus = (state) => state.BookingFilter.status;

export default BookingFilterSlice.reducer;
