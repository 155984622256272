import { Button } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { t } from "i18next";
import api from "../API/apiCollection";
import toast from "react-hot-toast";
import { resetState } from "../redux/cart";
import { orderCartDetailsReset } from "../redux/orderCartDetails";
import { deliveryAddressReset } from "../redux/DeliveryAddress";
import { useNavigate } from "react-router";
import { resetStatePromo } from "../redux/Promocode";
import PaystackPop from "@paystack/inline-js";

const Paystack = ({ amount, isCustomJobRequest, customJobReqData }) => {
  const dispatch = useDispatch();
  const payment_gateway = useSelector((state) => state.Settings)?.settings
    ?.payment_gateways_settings;
  const user_details = useSelector((state) => state.UserData)?.profile;
  const delivery_type = useSelector(
    (state) => state.DeliveryAddress
  )?.deliveryType;
  const deliveryAddress = useSelector(
    (state) => state.DeliveryAddress
  )?.delivery;
  const orderDetails = useSelector(
    (state) => state.OrderCartDetails
  )?.orderDetails;
  const date = orderDetails && orderDetails.date;
  const time = orderDetails && orderDetails.slot;
  const address_id = deliveryAddress;
  const address = address_id !== "" ? deliveryAddress : "";
  const orderNotes = orderDetails.orderNote;

  const navigate = useNavigate();

  const email = !user_details.email
    ? user_details?.data.email
    : user_details.email;

  const promocode = useSelector((state) => state.Promocode);
  const selectedPromoCode = promocode.selectedPromoCode;

  const handlePayout = async () => {
    if (!email) {
      toast.error("Please Update Your Email ID");
      return;
    }

    await api
      .placeOrder({
        method: "paystack",
        date: date,
        time: time,
        addressId: delivery_type === "Home" ? address?.id : "",
        order_note: orderNotes,
        promo_code_id: selectedPromoCode ? selectedPromoCode.id : "",
        custom_job_request_id: isCustomJobRequest
          ? customJobReqData?.custom_job_request_id
          : "",
        bidder_id: isCustomJobRequest ? customJobReqData?.partner_id : "",
      })
      .then((result) => {
        if (result.error === false) {
          const order_id = result.data.order_id;
          const onSuccess = (reference) => {
            api
              .add_transactions({
                orderID: order_id,
                status: "success",
              })
              .then((result) => {
                if (result.error) {
                  toast.error(result.message);
                } else {
                  toast.success("Service Booked successfully");
                }
              })
              .finally(() => {
                dispatch(resetState());
                dispatch(orderCartDetailsReset());
                dispatch(deliveryAddressReset());
                dispatch(resetStatePromo());
                // navigate(`/profile/booking/services/${order_id}`);
                navigate(`/payment-status?status=successful&order_id=${order_id}`);
              });
          };

          const onClose = () => {
            api.add_transactions({
              orderID: order_id,
              status: "cancelled",
              custom_job_request_id: isCustomJobRequest
                ? customJobReqData?.custom_job_request_id
                : "",
            });
          };

          // Initialize Paystack payment
          const paystack = new PaystackPop();
          paystack.newTransaction({
            key: payment_gateway.paystack_key,
            email: email,
            amount: amount * 100,
            currency: payment_gateway.paystack_currency,
            reference: new Date().getTime().toString(),
            metadata: {
              order_id: order_id,
            },
            onSuccess,
            onClose,
          });
        } else {
          toast.error(result.message);
        }
      });
  };

  return (
    <Button fullWidth sx={{ my: 1 }} variant="outlined" onClick={handlePayout}>
      {t("make_a_payment")} {t("with")} {t("paystack")}
    </Button>
  );
};

export default Paystack;
