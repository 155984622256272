import React, { useEffect } from "react";
import {
  AppBar,
  Box,
  FormControl,
  FormControlLabel,
  FormLabel,
  IconButton,
  MenuItem,
  OutlinedInput,
  Radio,
  RadioGroup,
  Select,
  ToggleButton,
  ToggleButtonGroup,
  Toolbar,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import { useTranslation } from "react-i18next";
import { useTheme } from "@emotion/react";
import { BsCloudSun, BsCloudMoon } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/store"; // Adjust the path as needed
import { isDarkMode, themeData, themeSuccess } from "../../../redux/Theme";
import config from "../../../config/config";
import { setLanguage } from "../../../redux/language";

// Define the props for EdemandSetting
interface EdemandSettingProps {
  changeLight: () => void;
  changeDark: () => void;
  setOpenSetting: (open: boolean) => void;
}

const EdemandSetting: React.FC<EdemandSettingProps> = ({
  changeLight,
  changeDark,
  setOpenSetting,
}) => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const darkMode = useSelector((state: RootState) => isDarkMode(state));
  const currentLanguage = useSelector(
    (state: RootState) => state.language.currentLanguage
  );
  const themedata = useSelector((state: RootState) => themeData(state));

  const theme = useTheme();

  // Handle closing the settings
  const handleCloseSetting = () => {
    setOpenSetting(false);
  };

  // Handle theme change
  const handleChangeTheme = (
    event: React.MouseEvent<HTMLElement>,
    nextView: string | null
  ) => {
    if (nextView) dispatch(themeSuccess(nextView));
  };


  // Handle language change
  const handleChangeLanguage = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    const selectedLangCode = event.target.value as string;
    const selectedLanguage = config.supportedLanguages.find(
      (lang) => lang.langCode === selectedLangCode
    );
    
    if (selectedLanguage) {
      dispatch(setLanguage(selectedLanguage)); // Update Redux state with new language
      // Reload translations dynamically after the language change
      i18n.changeLanguage(selectedLangCode).then(() => {
        // Force re-render of all components by updating the app's language context
        i18n.loadNamespaces('translation'); // Load the translations again if required
      });
    }
    handleCloseSetting();
  };

  return (
    <div>
      <Box width="100%">
        <AppBar
          position="static"
          sx={{
            width: "100%",
            display: "flex",
            height: "auto",
            justifyContent: "space-between",
            backgroundColor: theme?.palette?.primary?.main,
          }}
        >
          <Toolbar
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
              gap={2}
            >
              <IconButton
                size="medium"
                edge="start"
                color="inherit"
                aria-label="menu"
                sx={{
                  border: 1,
                  borderRadius: "12px",
                  backgroundColor: "white",
                  "&:hover": {
                    backgroundColor: "white",
                  },
                }}
              >
                <SettingsOutlinedIcon fontSize="medium" color="primary" />
              </IconButton>

              <Typography
                variant="body1"
                component="div"
                className="edemand-flexgrow"
              >
                {process.env.REACT_APP_NAME + " " + t("settings")}
              </Typography>
            </Box>
            <Box>
              <IconButton
                onClick={handleCloseSetting}
                sx={{
                  border: 1,
                  backgroundColor: "white",
                  padding: 0,
                  "&:hover": {
                    backgroundColor: "white",
                  },
                }}
                size="small"
              >
                <CloseIcon color="primary" fontSize="small" />
              </IconButton>
            </Box>
          </Toolbar>
        </AppBar>

        <Box display="flex" mx={3} my={5} flexDirection="column">
          <FormLabel sx={{ fontWeight: "bold" }}> {t("theme")} </FormLabel>
          <Box mt={1}>
            <ToggleButtonGroup
              value={themedata}
              exclusive
              onChange={handleChangeTheme}
              sx={{ width: "100%", display: "flex", gap: "2" }}
            >
              <Box
                display="flex"
                width="100%"
                gap={4}
                flexDirection={{ xs: "column", md: "row" }}
                justifyContent="space-between"
                alignContent="center"
              >
                <ToggleButton
                  onClick={changeLight}
                  value="list"
                  aria-label="list"
                  sx={{
                    backgroundColor: "#2560FC1A",
                    width: "100%",
                    "&:hover": {
                      backgroundColor: "#2560FC1A",
                    },
                  }}
                >
                  <Box
                    display="flex"
                    width="100%"
                    justifyContent="space-between"
                    alignContent="center"
                    gap={1}
                  >
                    <Box display="flex" alignContent="center" gap={2}>
                      <BsCloudSun className="theme-btn" />
                      <Box display="flex" flexDirection="column">
                        <Typography variant="body1">{t("light")}</Typography>
                        <Typography variant="caption">{t("mode")}</Typography>
                      </Box>
                    </Box>
                    <Box>
                      <RadioGroup
                        defaultValue="light"
                        name="radio-buttons-group"
                      >
                        <FormControlLabel
                          label=""
                          value={darkMode ? "dark" : "light"}
                          control={<Radio size="small" />}
                        />
                      </RadioGroup>
                    </Box>
                  </Box>
                </ToggleButton>

                <ToggleButton
                  onClick={changeDark}
                  value="module"
                  aria-label="module"
                  sx={{
                    width: "100%",
                    backgroundColor: "#343F53",
                    color: "white",
                    "&:hover": {
                      backgroundColor: "#343F53",
                    },
                  }}
                >
                  <Box
                    display="flex"
                    alignContent="center"
                    justifyContent="space-between"
                    width="100%"
                    gap={1}
                  >
                    <Box display="flex" alignContent="center" gap={2}>
                      <BsCloudMoon className="theme-btn" />
                      <Box display="flex" flexDirection="column">
                        <Typography variant="body1">{t("dark")}</Typography>
                        <Typography variant="caption">{t("mode")}</Typography>
                      </Box>
                    </Box>
                    <Box>
                      <RadioGroup
                        defaultValue="dark"
                        name="radio-buttons-group"
                      >
                        <FormControlLabel
                          label=""
                          value={darkMode ? "dark" : "light"}
                          control={<Radio size="small" />}
                        />
                      </RadioGroup>
                    </Box>
                  </Box>
                </ToggleButton>
              </Box>
            </ToggleButtonGroup>
          </Box>
        </Box>
        {/* Language selection section */}
        <Box px={3}>
          <FormControl fullWidth>
            <FormLabel>{t("language")}</FormLabel>
            <Select
              value={currentLanguage.langCode}
              onChange={handleChangeLanguage}
              input={<OutlinedInput />}
            >
              {config.supportedLanguages.map((lang) => (
                <MenuItem key={lang.langCode} value={lang.langCode}>
                  {lang.language}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      </Box>
    </div>
  );
};

export default EdemandSetting;
