import { Box } from "@mui/material";
import { FaSpinner } from "react-icons/fa";

const Loader = () => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backdropFilter: "blur(100px)", // Add background blur
      }}
    >
      <FaSpinner
        size={24}
        style={{
          animation: "spin 1s linear infinite", // Add spin animation
        }}
      />
      {/* Add the keyframes directly in CSS-in-JS or external CSS */}
      <style>
        {`
          @keyframes spin {
            0% {
              transform: rotate(0deg);
            }
            100% {
              transform: rotate(360deg);
            }
          }
        `}
      </style>
    </Box>
  );
};

export default Loader;
