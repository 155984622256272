import {
    Box,
    Breadcrumbs,
    Container,
    Grid,
    Pagination,
    Stack,
    Typography,
    useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { t } from "i18next";
import Heading from "./Heading";
import Pnavigation from "./Pnavigation";
import Layout from "../../layout/Layout";
import { BreadcrumbLink } from "../../../CSS/ThemeStyle";
import { Dropdown, Menu } from "antd";
import { IoIosArrowDown } from "react-icons/io";
import { useLocation, useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import api from "../../../API/apiCollection";
import { setBookings } from "../../../redux/Pages";
import { order_statues } from "../../../config/config";
import { BookingSkeleton } from "../Sections/Skeletons";
import BookingSection from "./Bookings/BookingSection";
import noServiceImage from "../../../Images/No-service.png";
import CustomJobReqestedCard from "../Cards/CustomJobReqestedCard";
import { selectBookingFilterStatus, updateFilterStatus } from "../../../redux/BookingFilter";


const Booking = () => {

    const dispatch = useDispatch();
    // const status = useSelector((state) => state?.BookingFilter);
    const status = useSelector(selectBookingFilterStatus);

    const company_name = process.env.REACT_APP_NAME;
    document.title = `Profile - Bookings | ${company_name}`;

    const settings = useSelector((state) => state.Settings)?.settings;
    const currency_symbol = settings?.app_settings?.currency;

    const theme = useTheme()

    const navigate = useNavigate();

    const location = useLocation();

    const pathName = location?.pathname

    // State to keep track of the selected tab
    const [activeTab, setActiveTab] = useState();

    const [hasMore, setHasMore] = useState(true);

    const [bookingDataLoaded, setBookingDataLoaded] = useState(false);

    const [isLoading, setIsLoading] = useState(false);

    const [booking, setBooking] = useState([]);

    const [currentPage, setCurrentPage] = useState(0);

    const [totalPage, setTotalPage] = useState();

    const [error, setError] = useState();

    const itemsPerPage = 5;

    // Handler to switch tabs
    const handleTabSwitch = (tab) => {
        setActiveTab(tab);
        setCurrentPage(0); // Reset page offset when the tab changes
        setBooking([]); // Clear booking data to avoid mix-up
        navigate(`/profile/${tab}`);
    };

    useEffect(() => {
        if (pathName === "/profile/general-booking") {
            setActiveTab("general-booking")
        } else if (pathName === "/profile/request-booking") {
            setActiveTab("request-booking")
        }
    }, [pathName])

    const handleStatusChange = (newStatus) => {
        dispatch(updateFilterStatus(newStatus)); // Update Redux status
    };

    // Status options
    const statusMenu = (
        <Menu
        onClick={(e) => handleStatusChange(e.key)}
        items={[
            { label: t("all"), key: "all" },
            { label: t("awaiting"), key: "awaiting" },
            { label: t("started"), key: "started" },
            { label: t("confirmed"), key: "confirmed" },
            { label: t("cancelled"), key: "cancelled" },
            { label: t("rescheduled"), key: "rescheduled" },
            { label: t("booking_ended"), key: "booking_ended" },
            { label: t("completed"), key: "completed" },
        ]}
    />
    );


    const fetchData = async () => {
        setIsLoading(true);
        setBookingDataLoaded(false);

        try {
            const result = await api.getOrders({
                offset: currentPage,
                limit: itemsPerPage,
                status: status,
                order_statuses: order_statues,
            });

            // Append new data if loading more; else, replace
            setBooking(prevBooking => (currentPage === 0 ? result.data : [...prevBooking, ...result.data]));
            setTotalPage(result.total);
            setHasMore(result.data.length === itemsPerPage); // Check if there's more data to load
            setError(result.error);

        } catch (error) {
            console.error("error", error);
        } finally {
            setIsLoading(false);
            setBookingDataLoaded(true);
        }
    };

    const fetchRequestedData = async () => {
        setIsLoading(true);
        setBookingDataLoaded(false);

        try {
            const result = await api.getOrders({
                offset: currentPage,
                limit: itemsPerPage,
                status: status,
                order_statuses: order_statues,
                custom_request_orders: "1",
            });

            // Append new data if loading more; else, replace
            setBooking(prevBooking => (currentPage === 0 ? result.data : [...prevBooking, ...result.data]));
            setTotalPage(result.total);
            setHasMore(result.data.length === itemsPerPage); // Check if there's more data to load
            setError(result.error);

        } catch (error) {
            console.error("error", error);
        } finally {
            setIsLoading(false);
            setBookingDataLoaded(true);
        }
    };
    // Handle load more
    const handleLoadMore = () => {
        setCurrentPage(prevPage => prevPage + itemsPerPage);
    };

    // Call fetchData or fetchRequestedData based on the active tab
    useEffect(() => {
        if (pathName === "/profile/general-booking") {
            fetchData();
        } else if (pathName === "/profile/request-booking") {

            fetchRequestedData();
        }
    }, [currentPage, status, pathName]);
    return (
        <Layout>

            <Box
                // bgcolor={theme.palette.background.heading}
                paddingTop={"35px"}
                paddingBottom={"35px"}
                mt={2}
            >
                <Container maxWidth="lg" className="mainContainer">
                    <Breadcrumbs
                        separator="|"
                        aria-label="breadcrumb"
                        className="mb-1 mt-1"
                    >
                        <BreadcrumbLink to={"/"} className="breadcrumb" sx={{ mb: 0 }}>
                            <strong> {t("home")}</strong>
                        </BreadcrumbLink>
                        <Typography color="text.primary">
                            <strong>{t("profile")}</strong>
                        </Typography>
                    </Breadcrumbs>
                    <Typography variant="h4" gutterBottom={true} sx={{ mt: "12px" }}>
                        <strong>{t("bookings")}</strong>
                    </Typography>
                </Container>
            </Box>

            <Container className="mainContainer" sx={{ mb: "30px", mt: "-50px" }}>

                <Grid spacing={3} container>
                    <Grid item xs={12} md={4}>
                        <Pnavigation />
                    </Grid>
                    <Grid item xs={12} md={8}>

                        <Box
                            sx={{ background: theme.palette.background.box }}
                            mt={3}
                            minHeight={570}
                            borderRadius={4}
                        >
                            <Heading heading={t("my_bookings")} />
                            <Box padding={2}>
                                <div className="filter_section">
                                    <div className="tabs">
                                        <button
                                            onClick={() => handleTabSwitch("general-booking")}
                                            className={`tab-button ${activeTab === "general-booking" ? "active" : "deactive"}`}
                                        >
                                            {t("general_booking")}
                                        </button>
                                        <button
                                            onClick={() => handleTabSwitch("request-booking")}
                                            className={`tab-button ${activeTab === "request-booking" ? "active" : "deactive"}`}
                                        >
                                            {t("requested_booking")}
                                        </button>
                                    </div>
                                    <div className="status_filter">
                                        <Dropdown overlay={statusMenu}>
                                            <button className="status-dropdown-btn">
                                                {t(status)} <IoIosArrowDown /> {/* Add the Down Arrow Icon */}
                                            </button>
                                        </Dropdown>
                                    </div>

                                </div>

                                <div className="tab_content">
                                    <>
                                        {bookingDataLoaded ? (
                                            booking && booking?.length > 0 ? (
                                                activeTab === "general-booking" ? (

                                                    <BookingSection booking={booking} />
                                                ) : (
                                                    <>
                                                        <CustomJobReqestedCard currency_symbol={currency_symbol} booking={booking} />
                                                    </>
                                                )
                                            ) : (
                                                <Box display="flex" justifyContent="center" m={2}>
                                                    <Box
                                                        display="block"
                                                        textAlign="center"
                                                        width="100%"
                                                        height="425px"
                                                    >
                                                        <Box
                                                            src={noServiceImage}
                                                            alt="No Booking Found"
                                                            sx={{
                                                                maxHeight: "100%",
                                                                maxWidth: "100%",
                                                            }}
                                                            component={"img"}
                                                        />
                                                        <Typography>{t("no_booking")}</Typography>
                                                    </Box>
                                                </Box>
                                            )
                                        ) : (
                                            // Display loading skeleton
                                            <Box>
                                                <BookingSkeleton />
                                                <BookingSkeleton />
                                                <BookingSkeleton />
                                                <BookingSkeleton />
                                            </Box>
                                        )}
                                    </>
                                </div>
                                {hasMore && (
                                    <div className="loadMoreService">
                                        <button onClick={handleLoadMore} disabled={isLoading}>
                                            {isLoading ? t("loading") : t("loadMore")}
                                        </button>
                                    </div>
                                )}
                                {/* <Box display={"flex"} justifyContent={"center"} mt={2}>
                                    {error === false && (
                                        <Stack spacing={2}>
                                            <Pagination
                                                count={totalPage}
                                                page={currentPage + 1}
                                                onChange={(event, page) => setCurrentPage(page - 1)}
                                            />
                                        </Stack>
                                    )}
                                </Box> */}
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        </Layout>
    )
}

export default Booking