import { Skeleton, Box } from '@mui/material';

const MyServiceReqCardSkeleton = () => {
    const maxVisibleBidders = 5;

    return (
        <div className='card myReqCard'>
            <Box className="service_div" sx={{ padding: '16px' }}>
                <div className="details" style={{ width: "100%" }}>
                    <Box className='info' sx={{ marginBottom: '16px', width: "100%" }}>
                        <Skeleton variant="text" width="30%" height={24} />
                        <Skeleton variant="text" width="60%" height={32} />
                        <Skeleton variant="text" width="50%" height={24} />
                    </Box>
                </div>
                <Skeleton variant="rectangular" width={100} height={32} />
            </Box>
            <Box className="bids_div" sx={{ padding: '16px' }}>
                <Box className="bidders" sx={{ display: 'flex', gap: '0' }}>
                    {[...Array(maxVisibleBidders)].map((_, index) => (
                        <Skeleton
                            style={{ marginRight: "-10px"}}
                            key={index}
                            variant="circular"
                            width={40}
                            height={40}
                        />
                    ))}
                    {/* <Skeleton variant="text" width="40px" height={40} /> */}
                </Box>
                <Skeleton variant="rectangular" width={120} height={36} />
            </Box>
        </div>
    );
};

export default MyServiceReqCardSkeleton;
