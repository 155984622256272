import { Box, Button, useTheme } from '@mui/material';
import { t } from 'i18next';
import { Link, useNavigate } from 'react-router-dom';
import { handleCustomJob } from '../../../redux/isCustomJobRequest';
import { useDispatch } from 'react-redux';
import { formatAmount, getStatusClassName } from '../../../util/Helper';

const CustomJobReqestedCard = ({ booking, currency_symbol }) => {

    const theme = useTheme();
    return (
        <>
            {booking &&
                booking.map((data) => {
                    return (
                        <Box
                            className="breadcrumb"
                            sx={{
                                color: theme.palette.color.navLink,
                            }}
                            key={data.id}
                        >
                            
                            <Box
                                sx={{
                                    width: "100%",
                                    border: "2px solid #dedddd",
                                    borderRadius: "10px",
                                    mb: 2,
                                    transition: "ease-in",
                                    "&:hover": {
                                        border: `2px solid ${theme.palette?.primary?.main}`, // Change the color on hover
                                    },
                                }}
                            >
                                <div className='card myReqCard'>
                                    <div className="service_div">
                                        <div className="details">

                                            <div className='info'>
                                                <span className="category">{data?.services[0]?.category_name}</span>
                                                <span className="name">{data?.services[0]?.service_title}</span>
                                                <span className="price">{t("service_at")}: {data?.address_id === "0" ? "Shop" : "Doorstep"}</span>
                                            </div>
                                        </div>

                                        <div className='service_otp'>

                                            <div className='otp_div'>
                                                otp : {data?.otp}
                                            </div>
                                            <Button
                                                variant="outlined"
                                                size="small"
                                                sx={{ borderColor: "#BFC1C9" }}
                                                className={`${getStatusClassName(data?.status)}`}
                           
                                            >
                                                {t(data?.status)}
                                            </Button>
                                        </div>

                                    </div>
                                    <div className="bids_div">
                                        <div>
                                            <span className='price'>{t("price")}</span> {""}
                                            <span>{currency_symbol}{formatAmount(data?.final_total)}</span>
                                        </div>
                                        <Link to={`/profile/booking/services/${data?.id}`}>
                                            <button className="view-details-btn" >{t("viewDetails")}</button>
                                        </Link>
                                    </div>
                                </div>
                            </Box>


                        </Box>
                    );
                })}
        </>
    )
}

export default CustomJobReqestedCard